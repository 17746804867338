import Card from '@mui/material/Card'
import Button  from '@mui/material/Button'
import React from 'react'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CardActions, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios'


const TarjetaPagos = () => {

    var token = "ZnVzYWdhc3VnYUBnbWFpbC5jb206RzNuM3IxYy04OTA2ODAwMDg="
    var objeto = {
        IDConversacion: localStorage.getItem("conversacion"),
        pregunta: "tipo de Tramite",
        respuesta: "",
        idTramite: 0,
        isTramite: 1,
        nit: localStorage.getItem("nit"),
        url: window.location.origin,
        ip: localStorage.getItem("IP"),
        idUser:localStorage.getItem("idUser")
      }
   
    const theme = createTheme({
        palette: {
          primary: {
            // Purple and green play nicely together.

            main: '#2ab9cb',
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#11cb5f',
          },
        },
    });
    
    return (
        <>
            <Grid container>
                <Grid item>
                    <Card sx={{
                        border: "",
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">Impuesto Predial</Typography>
                            <Typography variant="body2" textAlign="left">Para realizar el proceso de liquidación del impuesto predial haz clic en el siguiente botón:</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <ThemeProvider theme={theme}>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            objeto.idTramite=1
                                             axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                                headers: {
                                                  'Authorization': `Basic ${token}` 
                                                }
                                            })
                                            
                                            window.open('https://sinfanas02.myqnapcloud.com:8081/fusagasuga/')
                                            }} >impuesto predial
                                        </Button>
                                    </ThemeProvider>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card sx={{
                        border: "",
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">Impuesto Industria y comercio</Typography>
                            <Typography variant="body2" textAlign="left">En tiempos de cuarentena nuestra Alcaldía se renueva para ti, por esta razón tenemos habilitado el servicio:</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <ThemeProvider theme={theme}>
                                        <Button variant="contained" color="primary" onClick={() => {
                                              objeto.idTramite=2
                                              axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                                 headers: {
                                                   'Authorization': `Basic ${token}` 
                                                 }
                                             })
                                            window.open('https://sinfanas02.myqnapcloud.com:8081/fusagasuga/')
                                            }}>impuesto industria y comercio
                                        </Button>
                                    </ThemeProvider>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item width={'100%'}>
                    <Card  sx={{
  
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">¿Te puedo ayudar en algo más?</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <Button variant="contained" onClick={() => triggerNextStep({ trigger: 'opciones' })}>si</Button>
                                </FormControl>
                                <FormControl fullWidth>
                                    <Button variant="contained" onClick={() => triggerNextStep({ trigger: 'despedida' })}>no</Button>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
          
           

            
      </>

  )
}

export default TarjetaPagos