import Card from '@mui/material/Card'
import Button  from '@mui/material/Button'
import React from 'react'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CardActions, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios'


const TarjetaPQRSD = () => {
    var token = "ZnVzYWdhc3VnYUBnbWFpbC5jb206RzNuM3IxYy04OTA2ODAwMDg="

    var objeto = {
        IDConversacion: localStorage.getItem("conversacion"),
        pregunta: "tipo de Tramite",
        respuesta: "",
        idTramite: 0,
        isTramite: 1,
        nit: localStorage.getItem("nit"),
        url: window.location.origin,
        ip: localStorage.getItem("IP"),
        idUser:localStorage.getItem("idUser")
      }

    const theme = createTheme({
        palette: {
          primary: {
            // Purple and green play nicely together.
            main: '#2ab9cb',
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#11cb5f',
          },
        },
    });

    return (
        <>
            <Grid container>
                <Grid item>
                    <Card sx={{
                        border: "",
                        margin: "5px"
                    }}>
                        <CardContent>
                            <Typography variant="h5">PQRSD</Typography>
                            <Typography variant="body2" textAlign="left">Puedes registrar tu petición, queja, reclamo, sugerencia o denuncia a través de nuestro portal ingresando aquí:</Typography>
                            <CardActions>
                                <FormControl fullWidth>
                                    <ThemeProvider theme={theme}>
                                        <Button variant="contained" color="primary" onClick={() => {
                                             objeto.idTramite=3
                                             axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/log', objeto, {
                                                headers: {
                                                  'Authorization': `Basic ${token}`
                                                }
                                            })
                                            
                                            window.open('http://sac.gestionsecretariasdeeducacion.gov.co:2380/crm_sed_v30/index.php?req=gen&ent=25290')

                                            }}>realizar PQRSD
                                        </Button>
                                    </ThemeProvider>
                                </FormControl>
                            </CardActions>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>     
      </>

  )
}

export default TarjetaPQRSD