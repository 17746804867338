import React from 'react'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import TarjetaPagos from '../TarjetaPagos.js';
import TarjetaPQRSD from '../TarjetaPQRSD.js';
import Tramites from '../Tramites.js'
import axios from 'axios';

var fecha = new Date()
var token = "ZnVzYWdhc3VnYUBnbWFpbC5jb206RzNuM3IxYy04OTA2ODAwMDg="
localStorage.setItem("nit" , '890680008')
const conversacion = fecha.getFullYear() + fecha.getMonth().toString().padStart(2,'0') + fecha.getDate().toString().padStart(2,'0') + fecha.getTime()
localStorage.setItem('conversacion', conversacion)




const theme = {
    background: '#f5f8fb',
    headerBgColor: '#2ab9cb',
    headerFontColor: '#fff',
    headerFontSize: '20px',
    botBubbleColor: '#2ab9cb',
    botFontColor: '#fff',
    userBubbleColor: '#0cb3c9',
    userFontColor: '#fff',
}




export default function Contenido() {
    //const [nombre,setNonbre] = useState('')
        
        return (
            <ThemeProvider theme={theme}>
                <ChatBot
                    floatingStyle ={{marginBottom:'4%' }}
                    floating={true}
                    headerTitle="Asistente virtual"
                    height="700px"
                    width="600px"
                    steps={[
                        {
                            id: "1",
                            message: "¡Hola!, soy tu asesor en línea. Nos importa tu bienestar y salud, por eso te contamos que puedes hacer tus trámites sin salir de casa 🏠. Algunos los puedes realizar conmigo. Señor ciudadano por favor ingresa tu nombre.",
                            trigger: "name"
                        },
                        {
                            id: "name",
                            user: true,
                            validator: (dato) => {return datat(dato)},
                            trigger: 'opciones'
                        },
                        {
                            id: 'opciones',
                            message: "{previousValue}, ¿Que tipo de tramite desea realizar?: ",                     
                            trigger:'tipoTramite'
                        },
                        {
                            id: 'opciones2',
                            message: "¿Que tipo de tramite desea realizar?:",
                            trigger:'tipoTramite'
                        },
                        {
                            id: 'tipoTramite',
                            component: <Tramites ></Tramites>,
                            
                            
                        },
                        {
                            id: 'PAGO',
                            component: <TarjetaPagos ></TarjetaPagos>,
                            trigger:'algoMas'
                        },
                        {
                            id: "PQR",
                            component: <TarjetaPQRSD ></TarjetaPQRSD>,
                            trigger:'algoMas'
                        },
                        {
                            id: "fin",
                            message: "Gracias por hablar conmigo. ¡Adiós!",
                            end: true
                        },
                        {
                            id: 'algoMas',
                            message: '¿Te puedo ayudar en algo más?',
                            trigger:'algoMas1'
                        },
                        {
                            id: 'algoMas1',
                            options: [
                                {value:'si',label:'si',trigger:'opciones2'},
                                {vale:'no',label:'no',trigger:'fin'}
                            ]
                        },
                        {
                            id: "despedida",
                            message: "Gracias por hablar conmigo. ¡Adiós!",
                            end:true
                        }
                       
                    ]}
                    />
               
            </ThemeProvider>
        )

}

const datat = (dato) => {
    getIP()
    var objeto = {
        conversacion: localStorage.getItem("conversacion"),
        NombreCompleto: dato,
        identificacion: "",
        nit: localStorage.getItem("nit"),
        idChatbot:1
    }
    axios.post('https://apichatbot.1cero1.com/ChatBot/conversacion/crear', objeto, {
        headers: {
          'Authorization': `Basic ${token}` 
        }
    }).then((result) => { localStorage.setItem("idUser", result.data) })
    .catch((error)=>{console.log(error)})
    return true;
}

const getIP =async () => {
    axios.get('https://api.ipify.org?format=json')
        .then((results) => { localStorage.setItem("IP", results.data.ip) })
        .catch((error)=>{console.log(error)})
}


